self.addEventListener('install', (event) => {
    console.log("[ServiceWorker] event:install")
});

self.addEventListener('activate', (event) => {
    console.log("[ServiceWorker] event:activate")
});

self.addEventListener('fetch', function(event) {
    console.log("[ServiceWorker] event:fetch", event.request.url);

    const cacheDuration = 5 * 1000
    const url = new URL(event.request.url);

    if (['/api/manage/v1/race/global-update', '/api/manage/v1/race/events-update'].includes(url.pathname)) {
        console.log("[ServiceWorker] event:fetch:handle", url.pathname);
        const response = caches.open('v1') // only check in own cache
            .then((cache) => {
                return cache.match(event.request)
                    .then((response) => { // we found a cache
                        const date = new Date(response.headers.get('date'))
                        const age = Date.now() - date.getTime()

                        // check if the cache is to old ...
                        if (age > cacheDuration ) {
                            throw "cache outdated";
                        }

                        // valid cache found, return it
                        return new Response( // Build new Response to add custom Headers, as modifying the old response.headers is not working
                            response.body,
                            {
                                status: response.status,
                                statusText: response.statusText,
                                headers: new Headers([
                                    ...response.headers,
                                    ['X-SW-CACHE-DURATION', cacheDuration.toString()],
                                    ['X-SW-CACHE-AGE', age.toString()]
                                ])
                            }
                        );
                    })
                    .catch(() => {
                        // error, no cache or outdated cache, fetch it from server
                        return fetch(event.request)
                            .then((response) => {
                                const clonedResponse = response.clone();
                                if( clonedResponse.ok ) { // status code 200-299, so cache it
                                    cache.put(event.request, clonedResponse);
                                }
                                return response;
                            });
                })
        })

        event.respondWith(response);
    }

});